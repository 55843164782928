'use client';

import { CheckCircle, Clock, Download, FileUp, MailWarning, Play } from 'lucide-react';
import Link from 'next/link';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'sonner';

import useResumableUpload from '@/components/hooks/useResumableUpload';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { LoadingSpinner } from '@/components/ui/LoadingSpinner';
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectSeparator, SelectTrigger, SelectValue } from '@/components/ui/select';
import type { ClassifyConfigOption } from '@/lib/configs/adapters/classify';
import { trpc } from '@/lib/trpc';
import { cn } from '@/lib/utils/twutils';

import { demoAccounts, FintechVerticals } from './option';
import { z } from 'zod';
import { useToast } from '@/components/ui/use-toast';

export default function Page({ params: {} }: { params: { client: string } }) {
  const [flowId, setFlowId] = useState<number>();

  const { mutate, isPending: generatingFlow } = trpc.tools.duplicateFlow.useMutation({
    onSuccess: (data) => {
      setFlowId(data);
    },
  });

  const [options, setOptions] = useState<ClassifyConfigOption[]>([]);

  return (
    <div className="flex space-x-6 pt-6 pb-20">
      <div className="w-[67%] border rounded-xl shrink-0 flex overflow-hidden">
        <div className="border-r h-full w-[50%]">
          <Header title="1. Define your industries" />
          <Left onOptionsSelected={(options) => setOptions(options)} />
          <div className="p-5 border-t flex justify-end">
            <Button
              className="bg-purpleLight text-white hover:bg-purple/90 font-semibold"
              onClick={() => mutate({ client: 'mr-tool', flow_config_id: 2957, new_options: options })}
              disabled={options.length < 1}
            >
              Generate plan
            </Button>
          </div>
        </div>
        <div className="h-full w-[50%]">
          <Header title="2. Review the agent's plan" />
          <Right flowId={flowId} loading={generatingFlow} options={options} />
        </div>
      </div>
      <div className="min-h-full w-full rounded-xl bg-purple overflow-hidden">
        <Sample flowId={flowId} />
      </div>
    </div>
  );
}

// MARK: Plan
function Right(props: { flowId?: number; loading: boolean; options?: ClassifyConfigOption[] }) {
  const _options = useMemo(() => {
    if (!props.options) return [];
    return props.options.slice(0, 7).map((o) => o.name);
  }, [props.options]);

  return (
    <div className="p-6">
      {props.loading && (
        <div className="flex space-x-2">
          <LoadingSpinner className="text-purpleLight" />
          <span>Generating Plan...</span>
        </div>
      )}
      {props.flowId && (
        <div className="space-y-4">
          <PlanStep index={1} options={['Name', 'Description', 'Website URL']}>
            Selected <b>input data sources</b>
          </PlanStep>
          <PlanStep index={2} options={['Website URL']}>
            Browse company <b>website</b>
          </PlanStep>
          <PlanStep index={3} options={_options}>
            Generate <b>vertical classification</b>
          </PlanStep>
        </div>
      )}
    </div>
  );
}

// MARK: Plan Step
function PlanStep(props: React.PropsWithChildren & { index: number; options: string[] }) {
  return (
    <div>
      <div className="flex space-x-2">
        <div className="bg-purpleLight rounded-full p-1 h-5 w-5 text-[11px] font-bold  text-white flex items-center justify-center">
          <span>{props.index}</span>
        </div>
        <span className="opacity-50 text-sm">{props.children}</span>
      </div>
      <div className="flex flex-wrap ml-7 pt-2">
        {props.options.map((o) => (
          <span key={o} className="text-xs font-medium bg-zinc-400/20 text-zinc-500 rounded-sm px-2 py-1 mb-2 mr-2">
            {o}
          </span>
        ))}
        {props.options.length > 5 && <span className="text-sm opacity-50 underline cursor-pointer">more...</span>}
      </div>
    </div>
  );
}

// MARK: Vertical Selection
function Left(props: { onOptionsSelected: (options: ClassifyConfigOption[]) => void }) {
  const [selectedValue, setSelectedValue] = useState<string>();
  const [state, setState] = useState<'Upload CSV' | 'Uploading...' | 'Uploaded' | string>('Upload CSV');

  const filename = `${1234}-options`;

  const { upload } = useResumableUpload('momentum-supabase-prod-net-new-upload', filename, () => {
    mutate({ client: 'mr-tool', reportId: filename });
  });

  const { mutate } = trpc.tools.parseOptions.useMutation({
    onSuccess: (options) => {
      props.onOptionsSelected(options);
      setState(`Uploaded ${options.length} industries`);
    },
  });

  return (
    <div className="p-6 pt-3 pb-7 min-h-[130px]">
      <div className="space-y-3 flex flex-col items-start pt-3">
        <div className="flex items-center w-full">
          <Select
            onValueChange={(value) => {
              if (value === 'Fintech Industries') {
                props.onOptionsSelected(FintechVerticals);
              }
              setSelectedValue(value);
            }}
          >
            <SelectTrigger className=" w-[252px] border-zinc-200 ring-0 focus:ring-0 text-black ring-transparent">
              <SelectValue placeholder="Select industries" className="text-black" />
            </SelectTrigger>
            <SelectContent className="bg-white text-black">
              <SelectGroup className="bg-white">
                <SelectLabel>Pre-defined industries </SelectLabel>
                {/* <SelectSeparator /> */}
                <SelectItem className="bg-white text-black pl-8 py-2 text-sm" value="Fintech Industries">
                  Fintech Industries
                </SelectItem>
              </SelectGroup>
              <SelectSeparator />
              <SelectGroup className="bg-white">
                <SelectLabel>Custom</SelectLabel>
                <SelectItem className="bg-white text-black pl-8 py-2 text-sm" value="csv">
                  <div className="flex items-center">
                    <FileUp className="h-4 w-4 mr-2" />
                    <span>Upload a CSV</span>
                  </div>
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        {selectedValue === 'csv' && (
          <Link
            href={'https://docs.google.com/spreadsheets/d/1SlqFOGnW7nmR0gOjWxA2nzrU30CYWuxDgggoMmCD1y0/edit?gid=0#gid=0'}
            className="ml-0.5 underline text-zinc-400 text-xs mt-2"
            target="_blank"
          >
            Download template
          </Link>
        )}
        {selectedValue === 'csv' && (
          <div
            className="border border-dashed rounded flex items-center justify-center w-full h-[100px] cursor-pointer hover:bg-zinc-100/40 transition-all"
            onClick={() => {
              document.getElementById('import_csv_input_import_accounts')?.click();
            }}
          >
            {state.includes('Uploaded') ? <CheckCircle className="h-4 w-4 mr-2" /> : <FileUp className="h-4 w-4 mr-2" />}
            <span>{state}</span>
          </div>
        )}
        <Input
          type="file"
          name="csv_input"
          id="import_csv_input_import_accounts"
          hidden
          style={{ display: 'none' }}
          onChange={() => {
            const csv = (document.getElementById('import_csv_input_import_accounts') as HTMLInputElement).files?.[0];
            const reader = new FileReader();
            reader.onload = () => {
              if (csv) {
                upload(csv);
                setState('Uploading...');
              }
            };
            reader.readAsText(csv!);
          }}
        />
      </div>
    </div>
  );
}

// MARK: Sample
function Sample(props: { flowId?: number }) {
  const [csvUploaded, setCSVUploaded] = useState(false);
  const [state, setState] = useState<'Upload CSV (max 500)' | 'Uploading...' | 'Uploaded' | string>('Upload CSV (max 500)');
  const [selectedValue, setSelectedValue] = useState<string>('Demo Accounts');
  const [running, setRunning] = useState(false);
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState<string>();

  const { upload } = useResumableUpload('momentum-supabase-prod-net-new-upload', `${props.flowId}`, () => {
    setCSVUploaded(true);
    setState('Uploaded accounts');
  });

  const { mutate: run } = trpc.tools.run.useMutation({
    onMutate: () => {
      setRunning(true);
    },
    onSuccess: () => {
      setRunning(false);
      toast(
        <div className="text-black">
          <div className="flex items-center">
            <Clock className="pr-2" />
            <span className="text-sm font-semibold">Results on the way</span>
          </div>
          <span className="ml-6 block">
            Results will be delivered to your email <br /> in <b> 1-2 minutes</b>
          </span>
        </div>,
        { duration: 20_000 },
      );
      setSent(true);
    },
    onError: (error) => {
      setRunning(false);
      toast(
        <div className="text-black">
          <div className="flex items-center">
            <MailWarning className="pr-2 text-red-600" />
            <span className="text-sm font-semibold text-red-600">{error.message}</span>
          </div>
        </div>,
        { duration: 10_000 },
      );
    },
  });

  let runDisabled = true;
  if (selectedValue === 'Demo Accounts' && props.flowId) {
    runDisabled = false;
  }
  if (selectedValue === 'csv' && csvUploaded && props.flowId) {
    runDisabled = false;
  }

  return (
    <div className={cn('text-white relative h-full min-h-[490px]', { 'opacity-50 pointer-events-none': props.flowId === undefined })}>
      <div className="w-full h-[56px] bg-purpleLight pl-6 flex items-center">
        <h3 className="text-xl font-bold">3. Choose your accounts</h3>
      </div>
      <div className="p-6 space-y-3">
        <span className="text-sm font-bold block mb-3">Define your sample scope</span>
        <SampleSelect onValueChange={(value) => setSelectedValue(value)} />
        {selectedValue === 'csv' && (
          <Link
            href={'https://docs.google.com/spreadsheets/d/1Wq8MY-mhZR9criwab9szat90taJgKZTQgcDhXQjt0eM/edit?gid=0#gid=0'}
            className="ml-0.5 underline text-white text-xs pt-2 block"
            target="_blank"
          >
            Download template
          </Link>
        )}
        {selectedValue === 'csv' && (
          <div
            className="border border-dashed border-white/60 rounded flex items-center justify-center w-full h-[100px] cursor-pointer hover:bg-white/10 transition-all"
            onClick={() => {
              document.getElementById('import_accounts')?.click();
            }}
          >
            {state.includes('Uploaded') ? <CheckCircle className="h-4 w-4 mr-2" /> : <FileUp className="h-4 w-4 mr-2" />}
            <span>{state}</span>
          </div>
        )}
        <Input
          type="file"
          name="csv_input"
          id="import_accounts"
          hidden
          style={{ display: 'none' }}
          onChange={() => {
            const csv = (document.getElementById('import_accounts') as HTMLInputElement).files?.[0];
            const reader = new FileReader();
            reader.onload = () => {
              if (csv) {
                upload(csv);
                setState('Uploading...');
              }
            };
            reader.readAsText(csv!);
          }}
        />
      </div>
      <div className="border-t border-white/40 p-6 pt-3 absolute bottom-0 left-0 right-0 space-y-3">
        <span className="text-sm font-semibold -pb-2">Results will be sent via email</span>
        <Input
          placeholder="your@email.com"
          className="bg-white text-black focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0 border-none"
          value={email}
          disabled={running || sent}
          onChange={(value) => {
            setEmail(value.target.value);
          }}
        />
        <div className="w-full flex justify-end">
          <Button
            disabled={runDisabled || !z.string().email().safeParse(email).success || running || sent}
            className="text-purpleLight bg-white shrink-0 px-10"
            onClick={() =>
              run({ email: email!, client: 'mr-tool', tool_id: props.flowId!, accounts: selectedValue === 'Demo Accounts' ? demoAccounts : undefined })
            }
          >
            <Play className="h-4 w-4 mr-2 text-purpleLight" /> {running ? 'Sending...' : 'Run'}
          </Button>
        </div>
      </div>
    </div>
  );
}

function SampleSelect(props: { onValueChange: (value: string) => void }) {
  return (
    <Select onValueChange={props.onValueChange} defaultValue="Demo Accounts">
      <SelectTrigger className=" w-[252px] border-zinc-200 ring-0 focus:ring-0 text-black ring-transparent bg-white">
        <SelectValue placeholder="Select accounts" className="text-black" />
      </SelectTrigger>
      <SelectContent className="bg-white text-black">
        <SelectGroup className="bg-white">
          <SelectLabel>Pre-defined accounts </SelectLabel>
          <SelectItem className="bg-white text-black pl-8 py-2 text-sm" value="Demo Accounts">
            100 sample accounts
          </SelectItem>
        </SelectGroup>
        <SelectSeparator />
        <SelectGroup className="bg-white">
          <SelectLabel>Custom</SelectLabel>
          <SelectItem className="bg-white text-black pl-8 py-2 text-sm" value="csv">
            <div className="flex items-center">
              <FileUp className="h-4 w-4 mr-2" />
              <span>Upload a CSV</span>
            </div>
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

// MARK: Header
function Header(props: { title: string }) {
  return (
    <div className="w-full h-[56px] bg-landingGrey pl-6 flex items-center">
      <h3 className="text-xl font-bold">{props.title}</h3>
    </div>
  );
}
